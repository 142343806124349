/* globals sessionStorage, localStorage */

// ? ******************************************************************************************
// ?  Remove old storage with different build name
// ? ******************************************************************************************

export const clearOldStorage = (vertical) => {
  const projectName = process.env.NAME
  const storageKey = `${process.env.BUILD_NAME}_${vertical}`

  if (sessionStorage?.length) {
    for (let i = 0; i < sessionStorage.length; i++) {
      if (
        sessionStorage.key(i) &&
        sessionStorage.key(i).includes(projectName) &&
        sessionStorage.key(i).includes(vertical) &&
        sessionStorage.key(i) !== storageKey
      ) {
        sessionStorage.removeItem(sessionStorage.key(i))
      }
    }
  }

  if (localStorage?.length) {
    for (let i = 0; i < localStorage.length; i++) {
      if (
        localStorage.key(i) &&
        localStorage.key(i).includes(projectName) &&
        localStorage.key(i).includes(vertical) &&
        localStorage.key(i) !== storageKey
      ) {
        localStorage.removeItem(localStorage.key(i))
      }
    }
  }
}

// ? ******************************************************************************************
// ?  Local storage
// ? ******************************************************************************************

export const local = (vertical) => {
  const storageKey = `${process.env.BUILD_NAME}_${vertical}`

  return {
    key: storageKey,
    storage: window.localStorage,
    reducer: (state) => ({})
  }
}

export const verticalTracking = (vertical) => {
  const storageKey = `zmarta_tracking_${vertical}`

  return {
    key: storageKey,
    storage: window.localStorage,
    reducer: (state) => ({
      ...(vertical === 'cl' ? { cl: { tracking: state.cl.tracking } } : {}),
      ...(vertical === 'sme' ? { sme: { tracking: {} } } : {}),
      ...(vertical === 'mortgage' ? { mortgage: { tracking: {} } } : {})
    })
  }
}

// ? ******************************************************************************************
// ?  Session Storage
// ? ******************************************************************************************

export const session = (vertical) => {
  const storageKey = `${process.env.BUILD_NAME}_${vertical}`

  const cl = (state) => ({
    cl: {}
  })

  const sme = (state) => ({
    sme: {}
  })

  const mortgage = (state) => ({
    mortgage: {}
  })

  return {
    key: storageKey,
    storage: window.sessionStorage,
    reducer: (state) => ({
      ...(vertical === 'cl' ? cl(state) : {}),
      ...(vertical === 'sme' ? sme(state) : {}),
      ...(vertical === 'mortgage' ? mortgage(state) : {})
    })
  }
}

export const verticalSession = (vertical) => {
  return {
    key: `zmarta_session_${vertical}`,
    storage: window.sessionStorage,
    reducer: (state) => ({
      ...(vertical === 'cl' ? { cl: { session: state.cl.session } } : {}),
      ...(vertical === 'sme' ? { sme: { session: {} } } : {}),
      ...(vertical === 'mortgage' ? { mortgage: { session: {} } } : {})
    })
  }
}

export const zmartaSession = () => {
  return {
    key: 'zmarta_session',
    storage: window.sessionStorage,
    reducer: (state) => ({
      zmartaSession: state.zmartaSession
    })
  }
}
