import { hasProp } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {},

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getExperiments: state => Vue.prototype.TESTCAFE
      ? null
      : state
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async activateExperiment ({ state, commit }, experimentKey) {
      if (!experimentKey) {
        console.warn('experimentKey is missing')
        return false
      }

      const experiment = state?.[experimentKey]
      if (!experiment) return false

      commit('mutateActive', {
        experimentKey,
        activated: true
      })

      return await Vue.prototype.EXPERIMENTS.activate(experimentKey, {}, Vue.prototype.ZGA)
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateActive (state, payload) {
      if (hasProp(payload, 'activated') && hasProp(payload, 'experimentKey')) Vue.set(state[payload.experimentKey], 'activated', payload.activated)
    }
  }
}
