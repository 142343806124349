import ZmartaAnalytics from '@ocp-zmarta/zmarta-analytics'
import { currency, integer, isClient, sentryErrorHandler } from '@ocp-zmarta/zmarta-cl'
import ZmartaExperiments from '@ocp-zmarta/zmarta-experiments'
import Vue from 'vue'
import Meta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import VuexPersistence from 'vuex-persist'
import App from './App.vue'
import { createStore } from './store'
import { clearOldStorage, local, session, verticalSession, verticalTracking, zmartaSession } from './store/storage'

// vue config
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.debug = process.env.NODE_ENV !== 'production'

// global variables - less string literals in Vue components
Vue.prototype.PROJECT_NAME = process.env.NAME
Vue.prototype.BUILD_NAME = process.env.BUILD_NAME
Vue.prototype.BUILD_DATE = process.env.BUILD_DATE
Vue.prototype.LOCAL = process.env.LOCAL === true || process.env.LOCAL === 'true'

// global empty gif to reference to
Vue.prototype.LAZYIMAGE = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='

const filters = {
  currency,
  integer
}

Vue.prototype.FILTERS = filters

Object.keys(filters)
  .forEach(filter => {
    Vue.filter(filter, filters[filter])
  })

Vue.use(Meta)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  cancelable: true,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

export function createApp ({ vertical, brand, market, site, route = null, userAttributes = null, config = null }) {
  Vue.prototype.PRODUCTION = config?.env === 'production'
  Vue.prototype.DEVELOPMENT = config?.env === 'development'

  Vue.prototype.BRAND = brand
  Vue.prototype.ZMARTA = brand === 'zmarta'
  Vue.prototype.BROKER = brand === 'broker'

  Vue.prototype.MARKET = market
  Vue.prototype.SWEDEN = market === 'se'
  Vue.prototype.NORWAY = market === 'no'
  Vue.prototype.FINLAND = market === 'fi'

  Vue.prototype.VERTICAL = vertical
  Vue.prototype.CL = vertical === 'cl'
  Vue.prototype.SME = vertical === 'sme'
  Vue.prototype.MORTGAGE = vertical === 'mortgage'

  Object.keys(config.constants)
    .forEach(constant => {
      Vue.prototype[constant] = config.constants[constant]
    })

  const enableLogging = Vue.prototype.LOCAL || Vue.prototype.REVIEW || (route?.query && 'debug' in route.query)

  const zmartaExperiments = new ZmartaExperiments({
    brand,
    country: market,
    environment: process.env.NODE_ENV,
    options: {
      gaEventOnActivate: false,
      hotjarRecordingOnActivate: true,
      enableLogging,
      userId: route?.userId,
      userAgent: route?.userAgent,
      visitedUrl: route?.visitedUrl
    }
  })

  // mock cid when in development
  const cid = config?.env === 'development' ? 'cid' : null

  const zmartaAnalytics = new ZmartaAnalytics({
    brand,
    country: market,
    environment: config.env,
    productFamily: Vue.prototype.GA.PRODUCT_FAMILY,
    product: Vue.prototype.GA.PRODUCT,
    silence: !enableLogging,
    ...(cid && { cid })
  })

  const store = createStore({ vertical, brand, market, site, route, userAttributes, config })

  Vue.prototype.EVENT_BUS = new Vue()
  Vue.prototype.EXPERIMENTS = zmartaExperiments
  Vue.prototype.ZGA = zmartaAnalytics
  Vue.prototype.TESTCAFE = route?.query && 'testcafe' in route.query
  Vue.prototype.REVIEW = route?.query && 'review' in route.query
  Vue.prototype.IMG_SRC = `/${Vue.prototype.PROJECT_NAME}/images/${market}`

  const app = new Vue({
    store,
    async beforeCreate () {
      const vertical = this.$store.getters['router/getRoute']?.vertical

      if (vertical === 'cl' && !this.$store.hasModule('cl')) {
        const modules = (await import('./store/modules/cl')).default
        this.$store.registerModule('cl', modules, isClient() ? { preserveState: true } : {})
      } else if (vertical === 'sme' && !this.$store.hasModule('sme')) {
        const modules = (await import('./store/modules/sme')).default
        this.$store.registerModule('sme', modules, isClient() ? { preserveState: true } : {})
      } else if (vertical === 'mortgage' && !this.$store.hasModule('mortgage')) {
        const modules = (await import('./store/modules/mortgage')).default
        this.$store.registerModule('mortgage', modules, isClient() ? { preserveState: true } : {})
      }

      if (isClient()) {
        // clear all old storage
        clearOldStorage(vertical)

        // create storage plugins
        new VuexPersistence(local(vertical)).plugin(store)
        new VuexPersistence(session(vertical)).plugin(store)
        new VuexPersistence(verticalTracking(vertical)).plugin(store)
        new VuexPersistence(verticalSession(vertical)).plugin(store)
        new VuexPersistence(zmartaSession()).plugin(store)

        app.$mount(`#${this.PROJECT_NAME}`, true)
      }
    },
    errorCaptured (err, vm, info) {
      sentryErrorHandler(err, vm, info, this.PROJECT_NAME)
    },
    render: h => h(App)
  })

  return { app, store }
}
