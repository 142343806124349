import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} metric
 * @param {string} label
 * @returns {Promise<any>}
 */
export async function metric (metric, label) {
  return request({
    method: 'POST',
    url: '/loan-pages/metric',
    body: {
      metric,
      label
    }
  })
}
