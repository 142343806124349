import { request } from '../../utils'

/**
 * @param {string} email
 * @returns {Promise<any>}
 */
export async function addLead (customer) {
  return request({
    method: 'POST',
    url: '/service/common-gateway/api/v1/customer/addLead',
    data: customer
  })
}
