import requests from '../../requests'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async createCustomerLead ({ commit }, email) {
      await requests.serviceCommonGateway.addLead({
        email,
        vertical: 'consumer-loan'
      })
    },

    async verifyEmail ({ commit }, email) {
      const response = await requests.serviceCommonGateway.verifyEmail(email)
      return !!(response?.email && response?.email === email)
    }
  }
}
