import { hasProp, size } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    status: 0,
    step: 0,
    steps: [
      'calculator',
      'application',
      'my-account'
    ],
    selected: false,
    ctaInView: true
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getStatus: state => state.status,
    getStep: state => state.step,
    getSteps: state => state.steps,
    getCtaInView: state => state.ctaInView
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async nextStep ({ state, dispatch }) {
      if (state.selected) return
      await dispatch('changeStep', { step: state.step + 1 })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async nextStatus ({ state, dispatch }) {
      if (state.selected) return
      await dispatch('changeStatus', { status: state.status + 1 })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async prevStatus ({ state, dispatch }) {
      if (state.selected) return
      await dispatch('changeStatus', { status: state.status - 1 })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async changeStep ({ state, commit, dispatch }, { step }) {
      if (
        !step ||
        step === state.step ||
        step < 1 ||
        step > size(state.steps)
      ) return

      commit('mutateCampaign', { step })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async changeStatus ({ state, commit, dispatch }, { status }) {
      if (
        !status ||
        status === state.status ||
        status < 1
      ) return

      commit('mutateCampaign', { status })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    setSelected ({ commit }, selected) {
      commit('mutateCampaign', { selected })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async resetCampaign ({ commit }) {
      commit('mutateCampaign', {
        step: 0,
        status: 0,
        selected: false
      })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async setCtaInView ({ commit }, payload) {
      commit('mutateCampaign', { ctaInView: payload })
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateCampaign (state, payload) {
      if (hasProp(payload, 'status')) { Vue.set(state, 'status', payload.status) }
      if (hasProp(payload, 'step')) { Vue.set(state, 'step', payload.step) }
      if (hasProp(payload, 'selected')) { Vue.set(state, 'selected', payload.selected) }
      if (hasProp(payload, 'ctaInView')) { Vue.set(state, 'ctaInView', payload.ctaInView) }
    }
  }
}
