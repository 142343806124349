export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    locale: null,
    default: null,
    options: []
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getLocale: state => state.locale,
    getIsDefaultLocale: state => state.default === state.locale
  }
}
