import { getErrorName } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    statusMessage: null,
    modalMessage: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getStatusMessage: state => state.statusMessage,
    getModalMessage: state => state.modalMessage
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    setStatusMessage ({ commit, rootGetters }, { group, name }) {
      const statusMessage =
        rootGetters['content/getContent']?.statusMessages?.[group]?.[name] ||
        rootGetters['content/getContent']?.statusMessages?.[group]?.default

      commit('mutateMessages', { statusMessage })
    },

    // * **************************************************************************************
    // * **************************************************************************************

    setSuccessMessage ({ commit, dispatch, rootGetters }, { group, name, show }) {
      const modalMessage =
        rootGetters['content/getContent']?.successMessages?.[group]?.[name] ||
        rootGetters['content/getContent']?.successMessages?.[group]?.default

      commit('mutateMessages', { modalMessage })

      if (show) {
        dispatch('modal/showModal', {}, { root: true })
      }
    },

    // * **************************************************************************************
    // * **************************************************************************************

    setModalMessage ({ commit, dispatch, rootGetters }, { group, name, show }) {
      const modalMessage =
        rootGetters['content/getContent']?.modalMessages?.[group]?.[getErrorName(name)] ||
        rootGetters['content/getContent']?.modalMessages?.[group]?.default

      commit('mutateMessages', { modalMessage })

      if (show) {
        dispatch('modal/showModal', {}, { root: true })
      }
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateMessages (state, payload) {
      Vue.set(state, 'statusMessage', payload.statusMessage || null)
      Vue.set(state, 'modalMessage', payload.modalMessage || null)
    }
  }
}
