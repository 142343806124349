<template>
  <div
    :id="PROJECT_NAME"
    :data-brand="BRAND"
    :data-market="MARKET"
    :data-development="DEVELOPMENT"
    :data-production="PRODUCTION"
    :data-build-name="BUILD_NAME"
    :data-build-date="BUILD_DATE"
    :class="[{
      'error-404': isError404,
      'error-500': isError500,
      'application-page': isApplicationPage,
      'campaign-page': isCampaignPage
    }]"
  >
    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Header                                                                                 -->
    <!-- -------------------------------------------------------------------------------------- -->

    <lazy-hydrate never>
      <fragment
        :key="fragments.header.name"
        :name="fragments.header.name"
        :custom-query="fragmentCustomQuery"
      />
    </lazy-hydrate>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Content                                                                                -->
    <!-- -------------------------------------------------------------------------------------- -->

    <main-page
      :page="content"
      :site="getSite"
      :custom-template="customTemplate"
      :fragment-custom-query="fragmentCustomQuery"
      :class="['loan-pages', {
        'loan-pages--campaign': isCampaignPage,
        'loan-pages--application': isApplicationPage,
        'loan-pages--loaded': isLoaded
      }]"
    />

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Footer                                                                                 -->
    <!-- -------------------------------------------------------------------------------------- -->

    <lazy-hydrate never>
      <fragment
        :key="fragments.footer.name"
        :name="fragments.footer.name"
        :custom-query="fragmentCustomQuery"
      />
    </lazy-hydrate>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Chat                                                                                   -->
    <!-- -------------------------------------------------------------------------------------- -->

    <lazy-hydrate
      v-if="showChat"
      when-idle
    >
      <zg-chat
        :brand="BRAND"
        :country="MARKET"
        type="zopim"
      />
    </lazy-hydrate>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Theme                                                                                  -->
    <!-- -------------------------------------------------------------------------------------- -->

    <lazy-hydrate
      v-if="BROKER"
      never
    >
      <broker-theme
        :market="MARKET"
      />
    </lazy-hydrate>
  </div>
</template>

<script>
  import MainPage from '@content-sdk/layout/MainPage'
  import { removeUrlSearchParams, stateId } from '@ocp-zmarta/zmarta-cl'
  import LazyHydrate from 'vue-lazy-hydration'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'App',
    components: {
      LazyHydrate,
      MainPage,
      Fragment: () => import(/* webpackChunkName: 'components/elements/misc/fragment' */
        './components/elements/misc/Fragment'),
      BrokerTheme: () => import(/* webpackChunkName: 'components/elements/theme/broker-theme' */
        './components/elements/theme/BrokerTheme'),
      ZgChat: () => import(/* webpackChunkName: 'zc/zg-chat' */
        '@zc/components/ZgChat/ZgChat.vue')
    },
    data: () => ({
      isLoaded: false,
      testCafeActivation: false,
      hydrationTest: false
    }),
    computed: {
      ...mapGetters('router', ['getRoute', 'isCampaignPage', 'getSite']),
      ...mapGetters('content', ['getContent']),
      ...mapGetters('experiments', ['getExperiments']),
      ...mapGetters('features', ['getFeatures']),
      ...mapGetters('config', ['getConfig']),
      ...mapGetters('translations', ['getLocale', 'getIsDefaultLocale']),
      content () {
        return this.getContent
      },
      showChat () {
        return (
          this.getIsDefaultLocale &&
          !this.FINLAND &&
          !this.TESTCAFE &&
          !this.getFeatures?.['site-navigation']?.enabled
        )
      },
      isApplicationPage () {
        return this.getConfig?.useApplicationPageTemplate?.includes(this.getRoute?.path)
      },
      customTemplate () {
        if (this.isApplicationPage) return 'ApplicationPage'

        return null
      },
      fragments () {
        if (this.isApplicationPage || this.isCampaignPage) {
          return {
            header: { name: 'headerMinimal' },
            footer: { name: 'footerMinimal' }
          }
        }

        return {
          header: { name: 'header' },
          footer: { name: 'footer' }
        }
      },
      isError404 () {
        return this.getRoute?.status === 404
      },
      isError500 () {
        return this.getRoute?.status === 500
      },
      fragmentCustomQuery () {
        return {
          locale: this.getLocale,
          op_user_id: this.getRoute?.userId,
          show_translations: this.CL,
          ...(this.TESTCAFE ? { testcafe: true } : {})
        }
      }
    },
    async mounted () {
      this.addLeadooChatScript()
      this.deleteJwtParam()

      if (this.CL) {
        await this.setTracking()
        await this.setOrganicTracking()
      }

      setTimeout(async () => {
        await this.setGaClientId()
        await this.updateSession()
      }, 2000)

      this.isLoaded = true
    },
    methods: {
      ...mapActions('zmartaSession', ['setSlugs']),
      ...mapActions('features', ['features']),
      ...mapActions('cl/tracking', ['setTracking', 'setOrganicTracking', 'setGaClientId']),
      addLeadooChatScript () {
        if (
          !this.getFeatures?.leadoo?.enabled ||
          this.getFeatures?.['site-navigation']?.enabled
        ) return

        const elem = document.createElement('script')
        elem.innerText = require('../../content/fi/static/chatLeadoo').script
        document.head.appendChild(elem)
      },
      deleteJwtParam () {
        removeUrlSearchParams('jwt')
      },
      updateSession () {
        const serverState = window[stateId(this.PROJECT_NAME)]
        const slugs = serverState?.zmartaSession?.slugs

        this.setSlugs(slugs)
      }
    }
  }
</script>

<style lang="scss">
  @import '@zc/scss/themes/zmarta/global';
</style>

<style lang="scss" scoped>
  .application-page,
  .campaign-page {
    background-color: color(brand-100);
  }

  .loan-pages {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: rem(56px);

    &--application {
      padding-top: 0;
    }
  }

  /* stylelint-disable selector-id-pattern */
  ::v-deep section #U5ZPWwni7agLO22J7sqLF {
    display: none;
  }
</style>
