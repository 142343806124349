export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    attributes: null
  }
}
