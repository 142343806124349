/* istanbul ignore file */

import axios from 'axios'
class CustomError extends Error {
  constructor (error) {
    super()
    this.name = error?.name
    this.message = error?.message
    this.errorCode = error?.errorCode
    this.errorDetails = error?.errorDetails
  }
}

export async function request ({ method, url, headers, data, withCredentials }) {
  return axios({
    method,
    url,
    headers,
    data,
    withCredentials,
    timeout: 30000
  })
    .then((res) => {
      return res?.data
    })
    .catch(async e => {
      const error = e?.response?.data
      throw new CustomError(error)
    })
}

const failGracefully = ['/service/common-gateway/api/v1/customer/validate']

const resolveError = async (response, errorName, config) => {
  if (failGracefully.some(url => config.url.includes(url))) return
  return Promise.reject(response)
}

axios.interceptors.response.use(async response => {
  if (response.status !== 206) return response

  return resolveError(
    { response },
    response?.data?.name,
    response?.config
  )
}, async error => {
  return resolveError(
    error,
    error?.data?.name,
    error?.config
  )
})
