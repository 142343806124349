export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    vertical: null,
    brand: null,
    market: null,
    site: null,
    route: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getRoute: state => state?.route,
    getSite: state => state?.site,
    getQuery: state => state?.route?.query,
    getReferer: state => state?.route?.referer,
    isCampaignPage: state => state?.route?.path?.includes('/clp'),
    isInCampaignApplication: (state, getters, rootState, rootGetters) => getters.isCampaignPage && rootGetters['campaign/getStep'] === 1,
    isInCampaignMyAccount: (state, getters, rootState, rootGetters) => getters.isCampaignPage && rootGetters['campaign/getStep'] === 2
  }
}
