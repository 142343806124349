import campaign from './campaign'
import config from './config'
import content from './content'
import experiments from './experiments'
import features from './features'
import messages from './messages'
import misc from './misc'
import router from './router'
import translations from './translations'
import userAttributes from './user-attributes'
import zmartaSession from './zmarta-session'

export default {
  campaign,
  config,
  content,
  experiments,
  features,
  messages,
  misc,
  router,
  translations,
  userAttributes,
  zmartaSession
}
