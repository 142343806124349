var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        {
          "error-404": _vm.isError404,
          "error-500": _vm.isError500,
          "application-page": _vm.isApplicationPage,
          "campaign-page": _vm.isCampaignPage,
        },
      ],
      attrs: {
        id: _vm.PROJECT_NAME,
        "data-brand": _vm.BRAND,
        "data-market": _vm.MARKET,
        "data-development": _vm.DEVELOPMENT,
        "data-production": _vm.PRODUCTION,
        "data-build-name": _vm.BUILD_NAME,
        "data-build-date": _vm.BUILD_DATE,
      },
    },
    [
      _c(
        "lazy-hydrate",
        { attrs: { never: "" } },
        [
          _c("fragment", {
            key: _vm.fragments.header.name,
            attrs: {
              name: _vm.fragments.header.name,
              "custom-query": _vm.fragmentCustomQuery,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("main-page", {
        class: [
          "loan-pages",
          {
            "loan-pages--campaign": _vm.isCampaignPage,
            "loan-pages--application": _vm.isApplicationPage,
            "loan-pages--loaded": _vm.isLoaded,
          },
        ],
        attrs: {
          page: _vm.content,
          site: _vm.getSite,
          "custom-template": _vm.customTemplate,
          "fragment-custom-query": _vm.fragmentCustomQuery,
        },
      }),
      _vm._v(" "),
      _c(
        "lazy-hydrate",
        { attrs: { never: "" } },
        [
          _c("fragment", {
            key: _vm.fragments.footer.name,
            attrs: {
              name: _vm.fragments.footer.name,
              "custom-query": _vm.fragmentCustomQuery,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showChat
        ? _c(
            "lazy-hydrate",
            { attrs: { "when-idle": "" } },
            [
              _c("zg-chat", {
                attrs: { brand: _vm.BRAND, country: _vm.MARKET, type: "zopim" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.BROKER
        ? _c(
            "lazy-hydrate",
            { attrs: { never: "" } },
            [_c("broker-theme", { attrs: { market: _vm.MARKET } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }