import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} type
 * @param {string} name
 * @param {object} data
 * @returns {Promise<any>}
 */
export async function log (type, name, data) {
  return request({
    method: 'POST',
    url: '/loan-pages/log',
    body: {
      type,
      name,
      data
    }
  })
}
